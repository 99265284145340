<template>
  <a-card class="page-container">
    <search-form
      :form="searchForm"
      @search="handleSearch"
      @reset="handleReset"
    />

    <data-table
      :loading="loading"
      :data="activityList"
      :pagination="pagination"
      @action-click="handleActionClick"
      @pagination-change="handlePaginationChange"
    />

    <!-- 企微码 -->
    <a-modal
      v-model="codeModel"
      :mask-closable="false"
      :footer="null"
    >
      <div slot="title">
        绑定企微<span class="code_title">当前帐号没有绑定企微，请扫码绑定</span>
      </div>
      <div class="wx_reg">
        <div id="wx_reg"></div>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import SearchForm from './components/SearchForm'
import DataTable from './components/DataTable'
import activityApi from '@/api/activity'

export default {
  name: 'actAnalysisOperAssi',
  components: {
    SearchForm,
    DataTable
  },
  data () {
    return {
      codeModel: false,
      searchForm: {
        activityName: '',
        activityTime: []
      },
      loading: false,
      activityList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  created () {
    if (this.$route.query.ticket) {
      let params = {
        qy_wechat: this.$route.query.ticket
      }
      this.qyWechat(params)
    } else {
      this.$api.core.PutAssistant.judgeWechat().then(res => {
        if (res.data.code == 200) {
          if (res.data.data.oauth_user_id) {
            this.getData()
          } else {
            this.showCodeModel()
          }
        }
      })
    }
  },
  methods: {
    showCodeModel() {
      let URL = window.location.href;
      let params = {
        redirect_uri: URL,
      };
      
      this.$api.core.PutAssistant.wchatCode(params).then((res) => {
        const { agentid, appid, redirect_uri, stat } = res.data;

        window.WwLogin({
          id: "wx_reg",
          appid, //appid
          agentid, //agentid
          redirect_uri, //回调地址，注意回调地址需要进行urlencode
          state: stat, //用于保持请求和回调的状态，授权请求后原样带回给企业。该参数可用于防止csrf攻击,参数非必填
          // "href" : "XXXX",//自定义样式链接，企业可根据实际需求覆盖默认样式，参数非必填
        });
      });
      this.codeModel = true;
    },
    // 企微绑定
    qyWechat (params) {
      this.$api.core.PutAssistant.qyWechat(params).then((res) => {
        if (res.code === 200) {
          this.$router.push({ query: {} })
          this.getData()
        } else {
          this.$message.error(res.message)
          this.$router.push({ query: {} })
        }
      }).catch((err) => {
        this.$message.error(err)
      })
    },
    // 处理查询
    handleSearch () {
      this.pagination.current = 1
      this.getData()
    },
    // 处理重置
    handleReset () {
      this.pagination.current = 1
      this.getData()
    },
    // 处理分页改变
    handlePaginationChange (current, pageSize) {
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.getData()
    },
    // 获取数据
    async getData () {
      this.loading = true
      try {
        const { activityTime, ...restSearchForm } = this.searchForm
        const { current, pageSize } = this.pagination
        const params = {
          ...restSearchForm,
          publishTime: activityTime.length > 0 ? activityTime[0] : '',
          finishTime: activityTime.length > 0 ? activityTime[1] : '',
          page: current,
          size: pageSize
        }
        const { code, data, message } = await activityApi.fetchActivityListOper(params)
        
        if (code === 200) {
          this.activityList = data.list
          this.pagination.total = data.total
          this.loading = false
        } else {
          this.$message.error(message)
          this.loading = false
        }
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    // 处理操作点击
    handleActionClick (type, record) {
      if (type === 'content') {
        this.$router.push({
          name: 'actManage:contentOperAssi',
          query: {
            activityId: record.id
          }
        })
      }

      if (type === 'study') {
        this.$router.push({
          name: 'actManage:studyOperAssi',
          query: {
            activity_id: record.id
          }
        })
      }

      if (type === 'template') {
        this.$router.push({
          name: 'actManage:templateOperAssi',
          query: {
            activity_id: record.id  
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 0 !important;

  .data-table {
    margin: 10px 0 0;
  }
}

.code_title {
  color: #bbb;
  display: inline-block;
  margin-left: 12px;
  font-size: 12px;
}

.wx_reg {
  display: flex;
  justify-content: center;
}
</style>
